@import '../../layout/colors.scss';

.aicardContainer {
  margin: 0% 0% 0% 0%;
  border-radius: 10px;
  padding:0px;
  width: 175px;
  height: 130px;
 
  .aicardHeader {
    border-radius: 10px;
    padding: 3%;
    width: 175px;
    height: 65px;
    display: grid;
    .aiheaderTitle{
      text-transform: capitalize;
      font-size: 11pt;
      
    }
    .aiheaderValue{
      text-transform: capitalize;
      font-size: 14pt;
      font-weight: 700;
    }
  }
  // .gridCardHeader{
  //   border-radius: 10px;
  //   padding: 3%;
  //   margin: 0px;
  //    .headerTitle{
  //     text-transform: capitalize;
  //     font-size: 11pt;
      
  //   }
  //   .headerValue{
  //     text-transform: capitalize;
  //     font-size: 16pt;
  //     font-weight: 700;
  //   }
  // }
  .aicardFooter{
     display: grid;
     text-transform: capitalize;
     padding: 3%;
     width: 175px;
     height: 65px;
  }
}
.dark{
   background: $footer-blue;
   border-color: $card-grey-border;
   
    .cardHeader,.gridCardHeader {

    background: $header-blue;
    // box-shadow: 3px 2px 10px 1px #222;
    border-color: $card-grey-border;
    max-height: 66px;
  
    .headerTitle{
      color: rgb(53, 51, 51);
      
    }
    .headerValue{
      color: $white;
    }
  }
  .gridCardHeader,.gridCardFooter{
    .col-6:nth-child(1), .col-6:nth-child(1) .cardFooter {
      padding-left: 15px;
      .headerValue,.footerValue{
        color: $text-blue !important;
        font-weight: 800;
      }
    }
  }
  .aicardFooter{
     color: $white;
  }
}
.light{
   background: $light-grey-panel;
   border-color: $card-grey-border;
    .aicardHeader {

    background: $white;
    // box-shadow: 3px 2px 10px 1px #222;
    border-color: $card-grey-border;
  
    .aiheaderTitle, .aicardFooter{
      color:  $panel-text-color;
      
    }
    .aiheaderValue{
      color:  $text-blue;
    }
  }
  .aicardFooter{
     color: $panel-text-color;
  }
}
// .grid{
//   width: 100% !important;
//   height: auto;
// }
// .gridCardHeader {
//   .col-6{
//     padding: 0px 0px 0px 10px;
//   }
//   .headerTitle{
//     display: block;
//     width: 100%;
//   }
//   .headerValue{
//     display: inline-block;
//     font-size: 14px !important;
//     font-weight: 300;
//     word-break: break-all;
//     width: 100px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }
// .gridCardFooter{
//   margin: 0px;
// }