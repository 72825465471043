@import '../../layout/colors.scss';

.chartContainer {
  border-radius: 7px;
  padding: 1%;
  // border-color: $card-grey-border;
  margin: 0% 2% 2% 0%;
  // box-shadow: 3px 2px 10px 1px $black;
  // border: 2px solid $grey;
  min-height: 130px;
  [class^="col-"]{
      display: grid;
      padding: 1%;
  }
   .headerTitle{
      // color: $light-ash;
      text-transform: capitalize;
      font-size: 11pt;
      
    }
    .chartValue{
      // color: $white;
      font-size: 11pt;
      font-weight: 700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
}
.chartContainer.dark{
background: $footer-blue;
 .headerTitle{
      color: $light-ash;
      
    }
    .chartValue{
      color: $white;
    }
}
.chartContainer.light{
background: $white;
 .headerTitle{
      color: $black;
      
    }
    .chartTitle{
      color: $text-color;
    }
    .chartValue{
      color: $black;
    }
}
.mainDiv {
  height: 1rem;
  width: 200px;
  background-color: #ededf7;
  border-radius: 15px;
  margin: .3rem;
  
}
.childDiv {
  height: 100%;
  // width: 10%;
  // background-color:  #f80a0a;
  border-radius: 15px;
  text-align: right;

}
.text {
  color: rgb(47, 37, 37);
  padding-left: 30px;
  font-size: 11pt;
  font-weight: 700;
}
.test {
  float:left;
  width:100%;
}