@import '../../layout/colors.scss';

.imgCardContainer {
  border-radius: 5px;
  background: $white;
  padding: 0px;
  width: 270px;
  height: 120px;
  border-color: $card-grey-border;
  margin: 1.75%;
  color: $black;
  .imageDescription{
    display: table;
    
    span{
      display: table-cell;
      vertical-align: middle;
      text-transform: capitalize;
    }
  }
  // vertical-align: middle;
  .row{
    height: 100%;
  }
  .imageContainer{
    background: $panel-grey;
    border-radius: 5px;
  }
  .cardImg{
    height: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
  }
  .imgCardHeader {
    border-radius: 10px;
    background: $login-form-background;
    box-shadow: 0px 1px 1px 1px #222;
    border-color: $card-grey-border;
    padding: 3%;
    width: 300px;
    height: 65px;
    float: left;
    .imgHeaderTitle{
      color: $light-ash;
      text-transform: capitalize;
      font-size: 11pt;
      
    }
    .imgHeaderValue{
      text-transform: capitalize;
      color: $white;
      font-size: 16pt;
      font-weight: 700;
    }
  }
  .imgCardFooter{
     text-transform: capitalize;
     display: grid;
     color: $white;
     padding: 3%;
     width: 300px;
     height: 65px;
  }
}
.active .imgCardContainer{
  // border: 3px solid $yellow;
  box-shadow: 0px 4px 9px 5px #6e6c6c;
}