
@import "../colors.scss";

.title {
    color:$black;
    font-size: 16px;
    font-weight: 800;
   
}

.subtitle {
    color:$black;
    font-size: 16px;
    font-weight: 600;
  

}

.paragraph {
    color:$black;
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    text-justify: inter-word;
    overflow-y: auto;
    

}
.template-search-wrap{
    margin-top: 2%;
    margin-bottom: 32px;
    padding: 1%;
    .title{
        color:$black;
        font-size: 16px;
        font-weight: 800;
    }
}
.template-search-wrap>div{
    margin-bottom: 20px;
    padding: 20px;
    overflow: auto;
}
.custom-bullet {
    list-style-type: square; /* Changes bullet shape to square */
    color: rgb(41, 42, 41);            /* This doesn't change bullet color but affects text color */
  }
.bullet-div{
    padding-left: 50px;;
}  
  

