@import "../colors.scss";

.access-menu{
    margin-bottom: 32px;
    // border-right: 1px solid $form-input-border;
    // box-shadow: 3px 2px 4px 1px #000;
}
.sidemenuList{
    display: flex;
    margin: 1% 0% 0% 2%;
}
.selected{
    background: $text-blue !important;
    color: #fff;
    a{
        color: $white;
    }
}
.sidemenuList {
list-style: none;
padding: 0;
margin: 0;
width: 100%;
font-size: 12px;
li{
    margin: 1% 1% 0% 1%;
    height: initial !important;
    line-height: 6px !important;
    /* width: 5%; */
    background: $white;
    border-radius: 5px;
    text-align: left;
    display: table;
    flex-direction: row;
    padding: 1% 1% 1% 1%;
    align-items: center;
a{
    padding: 0 !important;
}
}
li a{
    display: block;
position: relative;
color:$grey;
 display: table-cell;
//color: #3a3b3c !important;
outline: 0;
cursor: pointer;
}

}
 ul li  a.selected::before, ul li  a.hover::before {
    width: 4px !important; 
}
ul  li  a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    background-color: $grey;
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out;
}
 ul.sidemenuList li > a.selected, ul li > a:selected {
    color: $yellow;
    background-color: transparent;
    font-weight: 600;
}
 ul> li > a::before {
    background-color: $yellow;
}
.container{
    margin-top: 74px;
height: 100%;
padding: 0;
color:$ash;
}
//.access-menu{
    
//     overflow: hidden;
//     max-width: 150px;
//     overflow: hidden;
// max-width: 200px;   
// padding-left: 10px;
// border-right: 1px solid #dfdfdf;
//}
.sidemenu-title {
    text-transform: uppercase;   
    margin-top: 20px;
    margin-bottom: 10px;   
}
h6{
    font-size: 12px;
}
.form-content{
    margin-bottom: 3%;
}
.create-az-btn{
    box-shadow: 0px 1px 0px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
background-color: $blue;
border-color: $blue;
color:$white;
float: right;
}
.resourceModal{
    top:3% !important;
}
.submit-btn{
    width: 100%;
    color:&white;
    background-color:$blue;
border-color: $blue;
}
.confirm-btn{
    position:relative;
    // left:20px;
    // top:100px;
    left:20px;
    top:10px;
    //margin: 20px 25px;
    width: 30%;
    color:$white;
    background-color:$blue;
    padding: 10px 10px;
border-color:$blue;
}
.confirm-btn-cancel{
    position:relative;
    left:300px;
   // top:10px;
    // margin: 20px 300px;
    width: 30%;
    color:$white;
    background-color:$blue;
    padding: 10px 10px;
border-color:$blue;
}
.creating{
    color:$dark-blue;
}
.created{
    color:$green
}
.Blink {
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}
ul li a{
    color: $black !important;
    margin-bottom: 1rem;
    font-weight: 800;
}
.table-wrap{
    margin:1% 1% 1% 1%;
    width: 100%;
}
.setting-table-wrap{
    margin: 3% 0% 0% 0%;
    padding: 18px 0px 8px 18px;

    // padding: 3%;
    background: $panel-grey;
    border-radius: 9px;
    .col-6{
        padding: 0px 17px 20px 0px;
        .col-12{
            padding-right: 0px;
        }
    }
   
}

.no-settings-found{
    margin: auto;
    width:50%;
    span{
        color: $black;
        display: block;
        width: 34%;
        text-align: center;
    }
    // img{
    //     height: 120px;
    // }
}
.progress-label-wrap{
   text-align: center;
    display: block;
    background: $header-blue;
    padding: 5px;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    z-index: 1;
    position: absolute;
    opacity: 0.8;
    width: 80%;
    border-radius: 2px;
    font-size: 18px;
    height: 32px;
    margin: 2% 5% 2% 1%;
    color: $white;
}
.org-panel{
    .settings-add{
       float: right; 
    }
   padding: 1%;
margin: 1% 0% -1% 0%;
color: $black;
// border-radius: 5px;
font-weight: 800;
display: block;
font-size: 16px;
color: $black;

}
table tr{
    line-height: 35px;
}
.buton-container{
    button{
        margin-right: 2%;
    }
    .create-vpc{
        background:  $yellow;
border: transparent;
color: $black-varient;
    }
    .create-vpn{
        background: $light-gray;
border: transparent;
    }
    margin: 2% 0% 0% 2%;
}
.table-apssword{
    border:none !important;
    background: none;
}
.mule-resource-sub{
    border-bottom: 1px solid $light-gray;
margin: 1%;
padding: 1%;
}
.settings-content{
    overflow-y: auto;
}
.settings-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.settings-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.toggled {
     transition: opacity 1s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
    // display: none;
    
}
.not-toggled{
      opacity: 1;
    height: auto;
   
    // background: $panel-white !important;
}
.org-toggle{
padding:1px 1px 1px 1px;
float: right;
}
ul.sidemenuList li.selected{
    a {
        color: $white !important;
    }
   
}
.error-container{
    width: 100%;
    text-align: center;
    background:$red ;
    color: $white;
    border-radius: 5px;
     display: none;
}
.error-container.true{
    display: block !important;
}
.invalid-value-wrap{
    text-align: left;
    label{
        color:$red;
    }

}

.azurecontainer {
    border-radius: 10px;
    border: 1px solid;
    background: $form-background;
    padding: 0px;
    width: 500px;
    height: 250px;
    border-color: $table-wrap-border;
  }

  .azurecolumn {
    float: left;
    width: 50%;
    padding: 25px;
     /* Should be removed. Only for demonstration */
  }
.org-panel-container{
    margin: 0% 0% 1% 0%;
}
.add-more-path{
    float: right;
    background: #fff;
    margin-bottom: 10px;
    height: 10px !important;
    border-radius: 2px;
}
.settings-details-row{
    width: 100%;
}
.settings-add{
    margin-left: 10px;
}
.ec2-instance-wrap{
    padding-top: 80px;
}
.mule-btn-wrap{
    margin: 1% 1% 1% 1%;
}