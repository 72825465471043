@import '../../layout/colors.scss';
.njc-icon{
    // background: #262932 !important;
padding: 5% 14% 1% 14%;
}
.logoutWrap{
    
}
 header,.customDrawer>div{
    background: $header-black !important;
    z-index: 100 !important;
}
.header-icon{
    width: 70%;;
}
header{
    background: $header-black !important;
    box-shadow: none !important;
}
.MuiDrawer-paperAnchorDockedLeft{
    border: none !important;
}
.menu-list-items{
    height: 100%;
    margin-top: 16px;
    background-color: #f4f4f8;
    color: $text-color
    a{
         color: $text-color !important
    }
}
.MuiSvgIcon-root{
    color: $text-color  !important;
}
.MuiList-root a{
    color: $text-color ;
}
.makeStyles-drawerPaper-9{
    width: 259px !important;
}