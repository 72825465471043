@import "../colors.scss";
.search-template{
    border: 1px solid transparent;
    background: $white;
    width: 35%;
    border-radius: 5px;
    height: 39px;
    color: $black;
    margin: 0% 1% 0% 1%;
    padding: 2px 2px 2px 13px;
}
.template-search-wrap{
    margin-top: 2%;
    margin-bottom: 32px;
    padding: 2%;
    .title{
        color:$black;
        font-size: 16px;
        font-weight: 800;
    }
}
.template-search-wrap>div{
    margin-bottom: 20px;
    padding: 32px;
}
.search-left-panel{
    border-right: 1px solid $search-left-border;
text-align: center;
color: $light-ash;
padding-left: 2%;
}
.searchButon{
    border-radius: 5px;
}