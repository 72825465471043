@import "../colors.scss";
@import 'https://fonts.googleapis.com/css?family=Satisfy';
@import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css';
@import 'https://fonts.googleapis.com/css?family=Inconsolata';
$blue: rgb(60, 180, 250);
$blue-lt: lighten($blue, 20%);
$text-dark: #636d84;
.sticky-top {
    position: sticky;
    top: 0px;
    z-index: 999;
}
.responsive {
    width: 100%;
    overflow-x: auto;
}
.customTabs {
    display: table;
    border-collapse: separate;
    table-layout: auto;
    margin-left: 2%;
    &.tabs-center {
        margin: auto;
    }
    &.tabs-justify {
        width: 100%;
        table-layout: fixed;
    }
    a.nav-item {
        position: relative;
        display: table-cell;
        transition: all ease .3s;
        padding: 1em 1.6em;
        transform: translate3d(0, 0, 0);
        color: $text-dark;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
            color: $blue;
        }
        &:after {
            transition: all .3s cubic-bezier(1, 0, 0, 1);
            will-change: transform, box-shadow, opacity;
            position: absolute;
            content: '';
            height: 3px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            border-radius: 3px 3px 0px 0px;
            background: $blue-lt;
            box-shadow: 0px 4px 10px 3px rgba($blue, .15);
            opacity: 0;
            transform: scale(0, 1);
        }
        &.active {
            color: $blue;
            &:after {
                opacity: 1;
                transform: scale(1, 1);
            }
        }
    }
}
.tab-content-data-wrap{
    margin: 2%;
}
.settings-add{
    margin-bottom: 2%;
}