@import "../colors.scss";
.modalStyle{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 500;
    background-color: $white;    
}
.root {
    display: flex;
    background: $dashboard-background-white;
    color:#000;
}
.content {
    flex-grow: 1;
    height: 100vh;
}
.project-overview-wrap{
    margin-left: 12pt;
}
.project-overview-wrap,.project-overview-container{
    height: 100%;
}
.project-overview-container{
    margin: 4% 0% 0% 1%;
}
.create-project-btn{
    // float: right;
    color: $white;
    background-color: $blue;
border-color: $blue;
}
.create-project-container-wrapper{
    position: absolute;
float: right;
right: 0px;
z-index: 1;
height: 100%;
}
.create-project-container{
    padding: 2%;
    border-left: 1px solid $light-ash;
    background-color: $white;
    border-radius: 5px;
    overflow: hidden;
    width: 400px;
    transition: right .4s ease;
    right: -700px;
    position: absolute;
    height: 100%;
}
.create-project-container.slide{

    width: 450px;
    right: 0px;
    position: relative;
    display: block;
    float: right;
    height: 100%;
    padding-top:25%;
}
.close-container{
    float: right;
top: 8%;
position: absolute;
right: 4%;
font-size: 17px;
font-weight: bold;
cursor: pointer;
}
select{
    margin: 2% 0% 2% 0%;
padding: 1%;
}
table{
    background-color: $white;
}
.projet-list-container{
    box-sizing: border-box;
display: flex;
flex-flow: column nowrap;
flex-grow: 1;
flex-shrink: 1;
flex-basis: initial;
place-content: space-between flex-start;
align-items: flex-start;
background-color: $project-list-background;
.details-panel{
    display: flex;
flex-direction: column;
width: 400px;
height: 100%;
background-color:$pjt-details-background;
overflow: hidden;
}
}

.projects-header-wrap{
   padding: 1% 2% 0% 0%;
   margin: 32px 65px 32px 1%;
}
.search-container{
    width: 100%;
    input{
        height: 40px;
padding: 10px 10px 10px 26px;
background: $white;
border: 1px solid $search-border;
box-sizing: border-box;
border-radius: 2px;
width: 100%;
    }
}
.projects-container{
    border-right: 1px solid $ash;
    overflow-y: scroll;
    overflow-x: hidden;
}
.project-side-desc-wrap{
   

padding: 0;
header{
width: 100%;
}
section{

}
}

table{
    // border: 1px solid #324462;
    thead{
        th{
            background: $table-background;
            border: none !important;
            border-bottom: 2px solid $ash !important;
            color:$black;
        }
    }
    tr:nth-child(even) td{
    background: $table-background;
    color: $black;
    }
    tr:nth-child(odd) td{
    background: $table-background; 
    color: $black;
    }
    td{
    border-bottom: none;
    border-top: none !important;
}
}
.table-wrap{
    border: 1px solid $table-wrap-border;
    border-radius: 11px;
    overflow: hidden;
    // padding: 1% 3% 1% 3%;
}
.sub-header{
    color: $black;
    font-weight: 700;
}
.project-container-row{
    height: 100%;
}
.validation-error{
    border: 1px solid $red !important;
}
table tr{
    line-height: 35px;
}
.carousel .control-dots {
    position: relative  !important;
   
}
.archetype-card {
     border: 2px solid transparent;
        padding: 5%;
    border-radius: 3px;
    background-color: $arctype-bg-color;
    color: $arctype-color;
    margin: 7% 0% 5% 0%;
    height: 81px;
    text-align: center;
    font-size: 11px;
    vertical-align: text-bottom;
    line-height: 10px;
    box-shadow:$arctype-box-shadow 1.95px 1.95px 2.6px;
}
.template-selected{
    border: $green;
}
.carousel.carousel-slider{
    height: inherit  !important;
}
.carousel-root{
    // height: 100px  !important;
}
.carousel .control-dots .dot{
    height: 8px !important;
}
div > .template-icon{
    display: none  !important;
}
.carousel .thumbs-wrapper {
    display: flex  !important;
    justify-content: center  !important;
}
.hide{
    display: none;
}
.show{
    display: block;
}
.project-step-btn-group{
    button{
        margin: 13pt;
        width: 20%;
    }
    .btn-secondary{
        background-color: $light-gray;
    }
    .btn-primary{
        background-color: $light-blue;
        color:$black;
    }
}
.project-name{
    width: 44%;
    color: $black;
    border-radius: 6pt;
    background-color: $white;
    border: none;
}
.project-name:focus, input:focus{
 box-shadow:1px 1px 1px 3px $light-ash;
 background-color: transparent !important;
 border-color: transparent !important;
 border: 1px solid $light-ash;
}
.card-row{   
    margin-left: 1%;;
    grid-gap: 25px
    
}
.card-body {   
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.75rem 2.25rem; 
    .card-body-title{
        font-size: 20px;
        color: $black;
    }
    // background-color: rgb(249, 244, 244);
   
  }
.text-label-wrap {
    padding-top:15px
}
.template-add-wrap{
    margin-top: 2%;
    margin-bottom: 32px;
    padding: 2%;
    .title{
        color:$black;
        font-size: 16px;
        font-weight: 800;
    }
}

.radio-button-label{
    color: $black;
    font-weight: 500;
}
.radio-button-wrap{
    // padding: 1% 3% 0% 0%;
    padding-left: 100px;
    margin: 32px 80px 50px 1%;
 }
 
//   .card-body:hover{
//     background-color: rgb(226, 224, 224);
//   }

  