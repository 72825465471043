@import "../colors.scss";
.project-header{
    text-align: center !important;
color: $project-header-color !important;
font-family: Open Sans !important;

}
.routes{
    background-image: linear-gradient(#009FDB, #004759);
}
div.form-input{
    background: $form-background;
   margin: 1% 1% 6% 1%;
    border:1px solid $form-input-border;
    input{
        padding: 3%;
        color:$form-input-field;
      
    }
    input:focus{
        background: none !important;
        border: none !important;
        outline: none !important;
    }
}
.login-card{
    display: inline-flex;
margin: auto;
}
form .forgot-pwd{
    float: right;
    color: $ash;
    
}
div.form-input::placeholder{
   color:$form-input-field
}
div .login-form{
background: $login-form-background;
color: $white;
}
.image-wrap{
    text-align: center;
    img{
        width: 70%;
    }
    label{
        font-weight: bolder;
        color: $form-input-field;
    }
}
.invalid-user-wrap{
    text-align: center;
    label{
        color: $red;
    }

}