@import '../../layout/colors.scss';

.cardContainer {
  margin: 0% 0% 20px 0%;
  border-radius: 10px;
  padding: 0px;
  width: 300px;
  height: 130px;
 
  .cardHeader {
    border-radius: 10px;
    padding: 3%;
    width: 300px;
    height: 65px;
    display: grid;
    .headerTitle{
      text-transform: capitalize;
      font-size: 11pt;
      
    }
    .headerValue{
      text-transform: capitalize;
      font-size: 14pt;
      font-weight: 700;
    }
  }
  .gridCardHeader{
    border-radius: 10px;
    padding: 3%;
    margin: 0px;
     .headerTitle{
      text-transform: capitalize;
      font-size: 11pt;
      
    }
    .headerValue{
      text-transform: capitalize;
      font-size: 16pt;
      font-weight: 700;
    }
  }
  .cardFooter{
     display: grid;
     text-transform: capitalize;
     padding: 3%;
     width: 300px;
     height: 65px;
  }
}
.dark{
   background: $footer-blue;
   border-color: $card-grey-border;
   
    .cardHeader,.gridCardHeader {

    background: $header-blue;
    // box-shadow: 3px 2px 10px 1px #222;
    border-color: $card-grey-border;
    max-height: 66px;
  
    .headerTitle{
      color: $light-ash;
      
    }
    .headerValue{
      color: $white;
    }
  }
  .gridCardHeader,.gridCardFooter{
    .col-6:nth-child(1), .col-6:nth-child(1) .cardFooter {
      padding-left: 15px;
      .headerValue,.footerValue{
        color: $text-blue !important;
        font-weight: 800;
      }
    }
  }
  .cardFooter{
     color: $white;
  }
}
.light{
   background: $light-grey-panel;
   border-color: $card-grey-border;
    .cardHeader {

    background: $white;
    // box-shadow: 3px 2px 10px 1px #222;
    border-color: $card-grey-border;
  
    .headerTitle, .cardFooter{
      color:  $panel-text-color;
      
    }
    .headerValue{
      color:  $text-blue;
    }
  }
  .cardFooter{
     color: $panel-text-color;
  }
}
.grid{
  width: 100% !important;
  height: auto;
}
.gridCardHeader {
  .col-6{
    padding: 0px 0px 0px 7px;
  }
  .headerTitle{
    display: block;
    width: 100%;
  }
  .headerValue{
    display: inline-block;
    font-size: 14px !important;
    font-weight: 300;
    word-break: break-all;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.gridCardFooter{
  margin: 0px;
}