$grey:#3a3b3c;
$yellow:#ffd630;
$ash: #c3c3c1;
$blue:#00a2df;
$white: #fff;
$dark-blue: blue;
$green:green;
$light-blue: #07bbdb;
$light-ash: #ccc;
$dark-grey:#262932;
$box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$black-varient: #292525;
$light-gray: gray;
$red:#851e1e;
$dashboard-background:#1d1e26;
$project-list-background:rgb(244, 245, 246);
$pjt-details-background:#f4f5f6;
$search-border:rgba(0,0,0,.1);
$table-even-background:#23262f; 
$table-odd-background:#262932;
$table-wrap-border:#324462;
$arctype-bg-color:#b7b6b6;
$arctype-color:#242020;
$arctype-box-shadow: rgba(108, 107, 107, 0.15);
$black:#000;
$project-header-color:#b3b3b3;
$form-background:#30363a;
$form-input-border:#3b4348;
$form-input-field:#adb5bd;
$login-form-background:#292e32;
$ul-li-color:#abe2f5;
$arrow-mask-background:beige;
$console-output-background:#1d1e22;
$site-footer-color:#9b9dad;
$site-footer-background:#2c303a;
$site-footer-border-top:#444857;
$footer-button-color:#b7bbc8;
$box-title-color:#aaaebc;
$dark-green:#1e6609;
$orange:orange;
$project-details-background:#131c29;
$status-body-completed-bkgnd:#239d2b;
$status-body-inprogress:#d9b426;
$error-label-wrap:#551c1c;
$console-command-background:rgba(255,255,255,0.1);
$search-left-border:#80808069;

$card-grey-border: #2c3038;
$light-grey-panel: #f4f4f8;
$button-blue: #00a2df;
$background-grey: #e3e3e3;
$text-color: #262932;
$panel-grey: #ededed;
$tab-select-active-blue: #4467cd;
$header-blue:#313976;
$footer-blue:#31467a;
$text-blue: #4467cd;
$header-black:#202124;
$dashboard-background-white:#e3e3e3;
$panel-white: #ededed;
$panel-text-color: #7b7b7b;
$table-background:#fcfcfc

